import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import {  LoginForm,  AccountRecovery,} from '..';

interface LoginProps {}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { t } = useTranslation();
  const [recoverySteps, setRecoverySteps] = useState(0);

  return (
    <>
      {recoverySteps === 0 ? (
        <>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant='h1' sx={{ fontWeight: 'bolder' }}>
              {t('texts.login')}
            </Typography>
          </Grid>
          <LoginForm
            recoverAccount={() => setRecoverySteps(recoverySteps + 1)}
          />
        </>
      ) : (
        <AccountRecovery recoverySteps={recoverySteps} setRecoverySteps={setRecoverySteps}/>
      )}
    </>
  );
};

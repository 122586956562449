import 'react-toastify/dist/ReactToastify.min.css';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ToastContainer, Slide } from 'react-toastify';
import { AnimateSharedLayout } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import {
  MainLayout,
  MuiThemeProvider,
  QueryProvider,
  RTL,
  // SplashScreen,
} from '@/components';
import { httpConfig, queryClient } from '@/config';
import { useApp } from '@/zustand';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache, request } from '@/helpers';
import { CssBaseline } from '@mui/material';
import '../i18n';
import { useRouter } from 'next/router';
import * as ga from '../lib/ga';
import axios from 'axios';
// import { apiURLs } from '@/config';
// import App from 'next/app';
// import Pusher from 'pusher-js';
import '../styles/globals.css';

const cache = createEmotionCache();

import { Sanctum, useSanctum } from 'react-sanctum';
import { sanctumConfig } from '@/config';
import { AuthProvider } from 'src/ui/components/dumb/app/AuthProvider';
import Echo from 'laravel-echo';
import { ErrorBoundary } from 'src/ui/components/dumb/app';

import { NextPageWithLayout } from 'src/app.types';
import ConnectionStatusIndicatorToast from 'src/ui/components/dumb/app/ConnectionStatusIndicatorToast';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
axios.defaults.withCredentials = true;

const MyApp: React.FC<AppProps> = ({
  Component,
  pageProps,
}: AppPropsWithLayout) => {
  const [initialized, setInitialized] = useState(false);
  const [direction] = useApp(state => [state.direction]);
  const getLayout =
    Component.getLayout ?? (page => <MainLayout>{page}</MainLayout>);
  const router = useRouter();

  useEffect(() => {
    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
      broadcaster: 'pusher',
      // key: 'Isbhzw.gjOWrA',//process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,
      //key: 'Isbhzw.i_tuDg', //this was the problem!
      key: process.env.NEXT_PUBLIC_ABLY_PUBLIC_KEY,
      wsHost: 'realtime-pusher.ably.io',
      wsPort: 443,
      disableStats: false,
      encrypted: true,
      cluster: 'mt1',
      forceTLS: true,
      auth: {
        withCredentials: true,
        headers: { origin: window.location.origin, Accept: 'application/json' },
      },
      authEndpoint: httpConfig.baseURL + '/broadcasting/auth',
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            request({
              method: 'post',
              url: 'broadcasting/auth',
              data: {
                socket_id: socketId,
                channel_name: channel.name,
              },
            })
              .then(response => {
                callback(null, response);
              })
              .catch(error => {
                callback(true, error);
              });
          },
        };
      },
    });
  }, []);

  React.useEffect(() => {
    setInitialized(true);
    const handleRouteChange = url => {
      // console.log('handleRouteChange: ' + url);
      ga.pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Sanctum config={sanctumConfig} checkOnInit={false}>
      <AuthProvider>
        <CacheProvider value={cache}>
          <RTL direction={direction}>
            <Head>
              <title>eProcurement</title>
              <meta
                name='viewport'
                content='minimum-scale=1, initial-scale=1, width=device-width'
              />
            </Head>
            <QueryProvider pageProps={pageProps}>
              <MuiThemeProvider>
                <CssBaseline />
                {initialized ? (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <AnimateSharedLayout type='crossfade'>
                      {getLayout(
                        <ErrorBoundary>
                          <Component {...pageProps} />
                        </ErrorBoundary>
                      )}
                    </AnimateSharedLayout>
                  </LocalizationProvider>
                ) : (
                  <></>
                )}
              </MuiThemeProvider>
            </QueryProvider>
          </RTL>
          <ToastContainer
            position={direction === 'ltr' ? 'top-right' : 'top-left'}
            autoClose={3500}
            hideProgressBar
            newestOnTop={false}
            theme='colored'
            closeOnClick
            rtl={direction === 'rtl'}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={3}
            transition={Slide}
          />
          <ConnectionStatusIndicatorToast />
        </CacheProvider>
      </AuthProvider>
    </Sanctum>
  );
};

// MyApp.getInitialProps = async appContext => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   await axios.get(apiURLs.crfCookie, {});
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps };
// };

const AppWithTranslation = appWithTranslation(MyApp);
const AppWithErrorBoundary = (props: AppPropsWithLayout) => (
  <ErrorBoundary>
    <AppWithTranslation {...props} />
  </ErrorBoundary>
);
export default AppWithErrorBoundary;

const get = (metric: NextWebVitalsMetric) => ({
  value: (metric.value / 1000)?.toFixed(4),
  startTime: (metric.startTime / 1000)?.toFixed(4),
});

export const reportWebVitals = (metric: NextWebVitalsMetric) => {
  let content: {
    name: string;
    value: string;
    startTime: string;
  } | null = null;
  switch (metric.name) {
    case 'FCP':
      content = {
        name: 'First Contentful Paint',
        ...get(metric),
      };
      break;

    case 'LCP':
      content = {
        name: 'Largest Contentful Paint',
        ...get(metric),
      };
      break;

    case 'CLS':
      content = {
        name: 'Cumulative Layout Shift',
        ...get(metric),
      };
      break;

    case 'FID':
      content = {
        name: 'First Input Delay',
        ...get(metric),
      };
      break;

    case 'TTFB':
      content = {
        name: 'Time to First Byte',
        ...get(metric),
      };
      break;

    case 'Next.js-hydration':
      content = {
        name: 'Length of time it takes for the page to start and finish hydrating',
        ...get(metric),
      };
      break;

    case 'Next.js-route-change-to-render':
      content = {
        name: 'Length of time it takes for a page to start rendering after a route change',
        ...get(metric),
      };
      break;

    case 'Next.js-render':
      content = {
        name: 'Length of time it takes for a page to finish render after a route change',
        ...get(metric),
      };
      break;

    default:
      break;
  }

  false &&
    !!content &&
    process.env.NODE_ENV !== 'production' &&
    console.table(content);
};
